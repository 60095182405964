import React from 'react';
import { makeStyles } from '@mui/styles';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Page } from '@backstage/core-components';
import { Header } from '@backstage/core-components';
import WorldClockHeader from '../worldClockHeader/WorldClockHeader';
import {
  HomePageCompanyLogo,
  HomePageToolkit,
  WelcomeTitle,
} from '@backstage/plugin-home';
import HomeTour from '../tour/HomeTour';
import { HomePageSearchBar } from '@backstage/plugin-search';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LogoFull from '../logos/LogoFull';
import { PagerdutyBodyContent } from '@internal/plugin-pagerduty-react';
import CatalogFeaturedCards from '../catalogFeaturedCards/CatalogFeaturedCards';
import SleuthLogo from '../logos/SleuthLogo';
import GitHubLogo from '../logos/GitHubLogo';
import BackstageLogo from '../logos/BackstageLogo';
import SonarcloudLogo from '../logos/SonarcloudLogo';
import JiraLogo from '../logos/JiraLogo';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    padding: '8px !important',
    borderRadius: '8px !important',
    margin: '0 auto 20px !important',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
    '& img': {
      maxWidth: '1200px',
    },
  },
}));

export const HomePage = () => {
  const { searchBar } = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home">
          <WorldClockHeader />
        </Header>

        <Content>
          <Grid container justifyContent="center">
            <Grid xs={12}>
              <HomeTour />
            </Grid>
          </Grid>

          <br />
          <br />

          <Grid container justifyContent="center" rowSpacing={5}>
            <HomePageCompanyLogo className={container} logo={<LogoFull />} />

            <Grid container xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                className="search-container"
                classes={{ root: searchBar }}
                placeholder="Search the portal"
              />
            </Grid>

            <Grid className="pagerduty-container" xs={12}>
              <PagerdutyBodyContent />
            </Grid>

            <Grid className="useful-links-section" xs={12}>
              <hr
                style={{
                  marginBottom: '30px',
                  width: '100%',
                  borderColor: '#6C38FF',
                }}
              />

              <Typography
                variant="h3"
                style={{ marginBottom: '30px', textAlign: 'center' }}
              >
                Featured
              </Typography>

              <CatalogFeaturedCards />
            </Grid>
          </Grid>

          <br />

          <Grid
            className="useful-links-section"
            container
            justifyContent="center"
            direction="column"
            style={{
              marginTop: '30px',
            }}
          >
            <HomePageToolkit
              title="Useful links"
              tools={[
                {
                  label: 'Sleuth',
                  url: 'https://app.sleuth.io/',
                  icon: <SleuthLogo />,
                },
                {
                  label: 'SonarQube',
                  url: 'https://sonarcloud.io/projects',
                  icon: <SonarcloudLogo />,
                },
                {
                  label: 'Portal Repo.',
                  url: 'https://github.com/NetManagement/backstage',
                  icon: <GitHubLogo />,
                },
                {
                  label: 'Portal Doc.',
                  url: 'https://backstage.net.management/docs/default/system/backstagedeveloperportal',
                  icon: <BackstageLogo />,
                },
                {
                  label: 'Portal Timeline',
                  url: 'https://netmanagement.atlassian.net/jira/plans/85/scenarios/98/timeline?vid=498',
                  icon: <JiraLogo />,
                },
              ]}
            />
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
