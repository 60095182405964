import useAsync from 'react-use/lib/useAsync';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { User } from '../types';

export const useFetchUsers = (scheduleId: string) => {
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);

  const date = new Date();
  const month = date.getMonth() + 1;
  const today = `${date.getFullYear()}-${month}-${date.getUTCDate()}`;

  return useAsync(async () => {
    try {
      const backendUrl = config.getString('backend.baseUrl');
      const url = `${backendUrl}/api/proxy/pagerduty/schedules/${scheduleId}/users?since=${today}T00%3A00%3A01&until=${today}T23%3A59%3A59`;

      const response = await fetch(url);
      const data = await response.json();

      return (data?.users as User[]) || [];
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', err);
      return err;
    }
  }, [scheduleId, config]);
};
