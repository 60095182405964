import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import EntityWarningContent from './EntityWarningContent';
import {
  EntityAboutCard,
  EntityHasSubcomponentsCard,
  EntitySwitch,
  isComponentType,
} from '@backstage/plugin-catalog';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import OnboardingButton from './OnboardingButton';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { hasLinks } from '../../utils';
import LinkCards from '../../linkCard/LinkCards';

const otherChecksId = [
  'groupOwnerCheck',
  'titleCheck',
  'techDocsCheck',
  'githubCheck',
  'lifecycleUnknown',
];

const OverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {EntityWarningContent}

    <Grid md={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={hasLinks}>
          <Grid xs={12}>
            <EntityAboutCard variant="gridItem" />
            <LinkCards />
          </Grid>
        </EntitySwitch.Case>

        <EntitySwitch.Case>
          <EntityAboutCard variant="gridItem" />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid md={6} xs={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isComponentType('service')}>
          <EntityTechInsightsScorecardCard
            title="Service onboarding checklist"
            checksId={[
              'groupOwnerCheck',
              'titleCheck',
              'techDocsCheck',
              'githubCheck',
            ]}
          />

          {OnboardingButton}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('website')}>
          <EntityTechInsightsScorecardCard
            title="Website onboarding checklist"
            checksId={[
              'groupOwnerCheck',
              'titleCheck',
              'techDocsCheck',
              'githubCheck',
              'websiteHasLinks',
            ]}
          />

          {OnboardingButton}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('library')}>
          <EntityTechInsightsScorecardCard
            title="Library onboarding checklist"
            checksId={otherChecksId}
          />

          {OnboardingButton}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('infrastructure')}>
          <EntityTechInsightsScorecardCard
            title="Infrastructure onboarding checklist"
            checksId={otherChecksId}
          />

          {OnboardingButton}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('other')}>
          <EntityTechInsightsScorecardCard
            title="Onboarding checklist"
            checksId={otherChecksId}
          />

          {OnboardingButton}
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid md={12} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <Grid md={12} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

export default OverviewContent;
